import React, { Fragment, FunctionComponent } from 'react'
import styled from 'styled-components'
import Typewriter from 'typewriter-effect'
import Button from '../components/button'
import Header from '../components/header'
import SEO from '../components/seo'
import Shape from '../components/shape'
import Tile from '../components/tile'
import { Title1, Title4 } from '../components/typography'
import Arrow from '../images/svg/arrow.svg'
import Etch from '../images/svg/etch-green-gum.svg'
import Fly from '../images/svg/fly.svg'
import {
  fadeInQuicklyAnimation,
  popInQuicklyAnimation,
  resetFadeFrames
} from '../styles/animations'
import { getMobileSize, getTabletSize } from '../styles/theme-selectors'
import fonts from '../styles/typography.module.sass'
import Layout from './layout'

const Main = styled.main`
  display: flex;
  flex-wrap: wrap;
  height: 100vh;
  width: 100vw;
`

const MainContent = styled.div`
  display: flex;
  flex: 1 0 ${getMobileSize};
  flex-direction: column;
  overflow: hidden;

  @media screen and (max-width: ${getMobileSize}) {
    flex: 1 1 auto;
  }
`

const ContentContainer = styled.div`
  flex: 3 1 auto;
  display: flex;
  align-items: center;
  padding: 0 0 0 8.5rem;

  @media screen and (max-width: ${getTabletSize}) {
    padding: 0;
  }
`

const Content = styled.div`
  margin: 1.5rem;
`

const Title1BottomSpaced = styled(Title1)`
  margin-bottom: 1rem;
`

const Title1WithBackground = styled(Title1)`
  .Typewriter {
    max-height: 0;
    transition: max-height 1s;
  }

  .Typewriter__cursor {
    display: none;
  }

  @media screen and (max-width: 27.5rem) {
    line-height: 3rem !important;
  }
`

const SubtitleVerticalSpaced = styled(Title4)`
  color: white;
  margin: 3.5rem 0;
  ${fadeInQuicklyAnimation}
  animation-delay: 3s;

  @media screen and (max-width: ${getMobileSize}) {
    margin: 1.5rem 0;
  }
`

const WhoWeAreButton = styled(Button)`
  width: 9rem;

  @media screen and (max-width: ${getMobileSize}) {
    width: 30vmin;
    font-size: 4vmin;
  }

  ${fadeInQuicklyAnimation}
  animation-delay: 4s;
`

const ArrowLeftSpaced = styled(Arrow)`
  margin-left: 0.5rem;
`

const TileContainer = styled.div`
  flex: 0 0 53vh;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;

  > a {
    &:first-child {
      margin: 0 0 0.75rem 0;
      ${popInQuicklyAnimation}
      animation-delay: 0.5s;
    }
    &:last-child {
      margin: 0.75rem 0 0 0;
      ${popInQuicklyAnimation}
      animation-delay: 1s;
    }
  }

  @media screen and (max-width: ${getTabletSize}) {
    flex: 1 0 50vw;
  }
`

const Footer = styled.footer`
  height: 6.25rem;
  position: relative;
`

const Circle = styled(Shape)`
  z-index: 1;
  position: absolute;
  left: 65%;
  top: -2.5rem;
  border-radius: 100%;
  width: 5rem;
  height: 5rem;

  @media screen and (max-width: ${getTabletSize}) {
    display: none;
  }

  transform: translateX(-100vw);
  animation: ${resetFadeFrames} 2s running forwards;
  animation-delay: 1s;
`

const SemiRectangle = styled(Shape)`
  z-index: 0;
  height: 300%;
  border-top-right-radius: 20rem;

  @media screen and (max-width: ${getTabletSize}) {
    display: none;
  }
`

const IndexPage: FunctionComponent = () => (
  <Layout>
    <SEO />
    <Main>
      <MainContent>
        <Header animated />
        <ContentContainer>
          <Content>
            <Title1BottomSpaced>
              <Typewriter
                onInit={typewriter =>
                  typewriter
                    .changeDelay(50)
                    .typeString('An amicable')
                    .callFunction(({ elements: { cursor } }) => cursor.remove())
                    .start()
                }
              />
            </Title1BottomSpaced>
            <Title1WithBackground className={fonts.withBackground}>
              <Typewriter
                onInit={typewriter =>
                  typewriter
                    .changeDelay(50)
                    .pauseFor(900)
                    .callFunction(({ elements: { container, cursor } }) => {
                      cursor.style.display = 'inline'
                      container.style.maxHeight = '20vh'
                    })
                    .typeString('digital product studio')
                    .callFunction(({ elements: { cursor } }) => cursor.remove())
                    .start()
                }
              />
            </Title1WithBackground>
            <SubtitleVerticalSpaced>
              creating brilliant user experiences
            </SubtitleVerticalSpaced>
            <WhoWeAreButton link="/who-we-are">
              Who we are <Arrow />
            </WhoWeAreButton>
          </Content>
        </ContentContainer>
        <Footer>
          <Circle color="pink" />
          <SemiRectangle />
        </Footer>
      </MainContent>
      <TileContainer>
        <Tile
          color="pink"
          image={<Etch />}
          text="Digital transformation done right"
          subText={
            <Fragment>
              What we do <ArrowLeftSpaced />
            </Fragment>
          }
          link="/what-we-do"
        />
        <Tile
          color="darkPurple"
          image={<Fly />}
          text="Wondering what to do next?"
          subText={
            <Fragment>
              Get in touch <ArrowLeftSpaced />
            </Fragment>
          }
          link="/get-in-touch"
        />
      </TileContainer>
    </Main>
  </Layout>
)

export default IndexPage
